define("ember-template-compiler/lib/plugins/assert-against-dynamic-helpers-modifiers", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display", "ember-template-compiler/lib/plugins/utils"], function (_exports, _debug, _calculateLocationDisplay, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = assertAgainstDynamicHelpersModifiers;

  function assertAgainstDynamicHelpersModifiers(env) {
    var _a;

    var moduleName = (_a = env.meta) === null || _a === void 0 ? void 0 : _a.moduleName;

    var _trackLocals = (0, _utils.trackLocals)(),
        hasLocal = _trackLocals.hasLocal,
        node = _trackLocals.node;

    return {
      name: 'assert-against-dynamic-helpers-modifiers',
      visitor: {
        Program: node,
        ElementNode: {
          keys: {
            children: node
          }
        },
        MustacheStatement: function MustacheStatement(node) {
          if ((0, _utils.isPath)(node.path)) {
            var name = node.path.parts[0];
            (true && !(name !== 'helper' && name !== 'modifier' || isLocalVariable(node.path, hasLocal)) && (0, _debug.assert)(messageFor(name) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), name !== 'helper' && name !== 'modifier' || isLocalVariable(node.path, hasLocal)));
          }
        },
        SubExpression: function SubExpression(node) {
          if ((0, _utils.isPath)(node.path)) {
            var name = node.path.parts[0];
            (true && !(name !== 'helper' && name !== 'modifier' || isLocalVariable(node.path, hasLocal)) && (0, _debug.assert)(messageFor(name) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), name !== 'helper' && name !== 'modifier' || isLocalVariable(node.path, hasLocal)));
          }
        }
      }
    };
  }

  function isLocalVariable(node, hasLocal) {
    return !node.this && node.parts.length === 1 && hasLocal(node.parts[0]);
  }

  function messageFor(name) {
    return "Cannot use the (" + name + ") keyword yet, as it has not been implemented.";
  }
});