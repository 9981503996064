"use strict";
var ES_UK_AUTOCOMPLETE = (() => {
  var __defProp = Object.defineProperty;
  var __defProps = Object.defineProperties;
  var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
  var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
  var __getOwnPropNames = Object.getOwnPropertyNames;
  var __getOwnPropSymbols = Object.getOwnPropertySymbols;
  var __hasOwnProp = Object.prototype.hasOwnProperty;
  var __propIsEnum = Object.prototype.propertyIsEnumerable;
  var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
  var __spreadValues = (a2, b2) => {
    for (var prop in b2 || (b2 = {}))
      if (__hasOwnProp.call(b2, prop))
        __defNormalProp(a2, prop, b2[prop]);
    if (__getOwnPropSymbols)
      for (var prop of __getOwnPropSymbols(b2)) {
        if (__propIsEnum.call(b2, prop))
          __defNormalProp(a2, prop, b2[prop]);
      }
    return a2;
  };
  var __spreadProps = (a2, b2) => __defProps(a2, __getOwnPropDescs(b2));
  var __export = (target, all) => {
    for (var name in all)
      __defProp(target, name, { get: all[name], enumerable: true });
  };
  var __copyProps = (to, from, except, desc) => {
    if (from && typeof from === "object" || typeof from === "function") {
      for (let key of __getOwnPropNames(from))
        if (!__hasOwnProp.call(to, key) && key !== except)
          __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
    }
    return to;
  };
  var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
  var __publicField = (obj, key, value) => {
    __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
    return value;
  };

  // src/index.tsx
  var src_exports = {};
  __export(src_exports, {
    createAutocomplete: () => createAutocomplete
  });

  // node_modules/.pnpm/preact@10.16.0/node_modules/preact/dist/preact.module.js
  var n;
  var l;
  var u;
  var t;
  var i;
  var o;
  var r;
  var f;
  var e;
  var c = {};
  var s = [];
  var a = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i;
  var h = Array.isArray;
  function v(n2, l2) {
    for (var u2 in l2)
      n2[u2] = l2[u2];
    return n2;
  }
  function p(n2) {
    var l2 = n2.parentNode;
    l2 && l2.removeChild(n2);
  }
  function y(l2, u2, t2) {
    var i2, o3, r2, f2 = {};
    for (r2 in u2)
      "key" == r2 ? i2 = u2[r2] : "ref" == r2 ? o3 = u2[r2] : f2[r2] = u2[r2];
    if (arguments.length > 2 && (f2.children = arguments.length > 3 ? n.call(arguments, 2) : t2), "function" == typeof l2 && null != l2.defaultProps)
      for (r2 in l2.defaultProps)
        void 0 === f2[r2] && (f2[r2] = l2.defaultProps[r2]);
    return d(l2, f2, i2, o3, null);
  }
  function d(n2, t2, i2, o3, r2) {
    var f2 = { type: n2, props: t2, key: i2, ref: o3, __k: null, __: null, __b: 0, __e: null, __d: void 0, __c: null, __h: null, constructor: void 0, __v: null == r2 ? ++u : r2 };
    return null == r2 && null != l.vnode && l.vnode(f2), f2;
  }
  function k(n2) {
    return n2.children;
  }
  function b(n2, l2) {
    this.props = n2, this.context = l2;
  }
  function g(n2, l2) {
    if (null == l2)
      return n2.__ ? g(n2.__, n2.__.__k.indexOf(n2) + 1) : null;
    for (var u2; l2 < n2.__k.length; l2++)
      if (null != (u2 = n2.__k[l2]) && null != u2.__e)
        return u2.__e;
    return "function" == typeof n2.type ? g(n2) : null;
  }
  function m(n2) {
    var l2, u2;
    if (null != (n2 = n2.__) && null != n2.__c) {
      for (n2.__e = n2.__c.base = null, l2 = 0; l2 < n2.__k.length; l2++)
        if (null != (u2 = n2.__k[l2]) && null != u2.__e) {
          n2.__e = n2.__c.base = u2.__e;
          break;
        }
      return m(n2);
    }
  }
  function w(n2) {
    (!n2.__d && (n2.__d = true) && i.push(n2) && !x.__r++ || o !== l.debounceRendering) && ((o = l.debounceRendering) || r)(x);
  }
  function x() {
    var n2, l2, u2, t2, o3, r2, e2, c2, s2;
    for (i.sort(f); n2 = i.shift(); )
      n2.__d && (l2 = i.length, t2 = void 0, o3 = void 0, r2 = void 0, c2 = (e2 = (u2 = n2).__v).__e, (s2 = u2.__P) && (t2 = [], o3 = [], (r2 = v({}, e2)).__v = e2.__v + 1, L(s2, e2, r2, u2.__n, void 0 !== s2.ownerSVGElement, null != e2.__h ? [c2] : null, t2, null == c2 ? g(e2) : c2, e2.__h, o3), M(t2, e2, o3), e2.__e != c2 && m(e2)), i.length > l2 && i.sort(f));
    x.__r = 0;
  }
  function P(n2, l2, u2, t2, i2, o3, r2, f2, e2, a2, v2) {
    var p2, y2, _2, b2, g2, m2, w2, x2, P2, S, H2 = 0, I2 = t2 && t2.__k || s, T2 = I2.length, j2 = T2, z2 = l2.length;
    for (u2.__k = [], p2 = 0; p2 < z2; p2++)
      null != (b2 = u2.__k[p2] = null == (b2 = l2[p2]) || "boolean" == typeof b2 || "function" == typeof b2 ? null : "string" == typeof b2 || "number" == typeof b2 || "bigint" == typeof b2 ? d(null, b2, null, null, b2) : h(b2) ? d(k, { children: b2 }, null, null, null) : b2.__b > 0 ? d(b2.type, b2.props, b2.key, b2.ref ? b2.ref : null, b2.__v) : b2) && (b2.__ = u2, b2.__b = u2.__b + 1, -1 === (x2 = A(b2, I2, w2 = p2 + H2, j2)) ? _2 = c : (_2 = I2[x2] || c, I2[x2] = void 0, j2--), L(n2, b2, _2, i2, o3, r2, f2, e2, a2, v2), g2 = b2.__e, (y2 = b2.ref) && _2.ref != y2 && (_2.ref && O(_2.ref, null, b2), v2.push(y2, b2.__c || g2, b2)), null != g2 && (null == m2 && (m2 = g2), S = !(P2 = _2 === c || null === _2.__v) && x2 === w2, P2 ? -1 == x2 && H2-- : x2 !== w2 && (x2 === w2 + 1 ? (H2++, S = true) : x2 > w2 ? j2 > z2 - w2 ? (H2 += x2 - w2, S = true) : H2-- : H2 = x2 < w2 && x2 == w2 - 1 ? x2 - w2 : 0), w2 = p2 + H2, S = S || x2 == p2 && !P2, "function" != typeof b2.type || x2 === w2 && _2.__k !== b2.__k ? "function" == typeof b2.type || S ? void 0 !== b2.__d ? (e2 = b2.__d, b2.__d = void 0) : e2 = g2.nextSibling : e2 = $(n2, g2, e2) : e2 = C(b2, e2, n2), "function" == typeof u2.type && (u2.__d = e2)));
    for (u2.__e = m2, p2 = T2; p2--; )
      null != I2[p2] && ("function" == typeof u2.type && null != I2[p2].__e && I2[p2].__e == u2.__d && (u2.__d = I2[p2].__e.nextSibling), q(I2[p2], I2[p2]));
  }
  function C(n2, l2, u2) {
    for (var t2, i2 = n2.__k, o3 = 0; i2 && o3 < i2.length; o3++)
      (t2 = i2[o3]) && (t2.__ = n2, l2 = "function" == typeof t2.type ? C(t2, l2, u2) : $(u2, t2.__e, l2));
    return l2;
  }
  function $(n2, l2, u2) {
    return null == u2 || u2.parentNode !== n2 ? n2.insertBefore(l2, null) : l2 == u2 && null != l2.parentNode || n2.insertBefore(l2, u2), l2.nextSibling;
  }
  function A(n2, l2, u2, t2) {
    var i2 = n2.key, o3 = n2.type, r2 = u2 - 1, f2 = u2 + 1, e2 = l2[u2];
    if (null === e2 || e2 && i2 == e2.key && o3 === e2.type)
      return u2;
    if (t2 > (null != e2 ? 1 : 0))
      for (; r2 >= 0 || f2 < l2.length; ) {
        if (r2 >= 0) {
          if ((e2 = l2[r2]) && i2 == e2.key && o3 === e2.type)
            return r2;
          r2--;
        }
        if (f2 < l2.length) {
          if ((e2 = l2[f2]) && i2 == e2.key && o3 === e2.type)
            return f2;
          f2++;
        }
      }
    return -1;
  }
  function H(n2, l2, u2, t2, i2) {
    var o3;
    for (o3 in u2)
      "children" === o3 || "key" === o3 || o3 in l2 || T(n2, o3, null, u2[o3], t2);
    for (o3 in l2)
      i2 && "function" != typeof l2[o3] || "children" === o3 || "key" === o3 || "value" === o3 || "checked" === o3 || u2[o3] === l2[o3] || T(n2, o3, l2[o3], u2[o3], t2);
  }
  function I(n2, l2, u2) {
    "-" === l2[0] ? n2.setProperty(l2, null == u2 ? "" : u2) : n2[l2] = null == u2 ? "" : "number" != typeof u2 || a.test(l2) ? u2 : u2 + "px";
  }
  function T(n2, l2, u2, t2, i2) {
    var o3;
    n:
      if ("style" === l2)
        if ("string" == typeof u2)
          n2.style.cssText = u2;
        else {
          if ("string" == typeof t2 && (n2.style.cssText = t2 = ""), t2)
            for (l2 in t2)
              u2 && l2 in u2 || I(n2.style, l2, "");
          if (u2)
            for (l2 in u2)
              t2 && u2[l2] === t2[l2] || I(n2.style, l2, u2[l2]);
        }
      else if ("o" === l2[0] && "n" === l2[1])
        o3 = l2 !== (l2 = l2.replace(/Capture$/, "")), l2 = l2.toLowerCase() in n2 ? l2.toLowerCase().slice(2) : l2.slice(2), n2.l || (n2.l = {}), n2.l[l2 + o3] = u2, u2 ? t2 || n2.addEventListener(l2, o3 ? z : j, o3) : n2.removeEventListener(l2, o3 ? z : j, o3);
      else if ("dangerouslySetInnerHTML" !== l2) {
        if (i2)
          l2 = l2.replace(/xlink(H|:h)/, "h").replace(/sName$/, "s");
        else if ("width" !== l2 && "height" !== l2 && "href" !== l2 && "list" !== l2 && "form" !== l2 && "tabIndex" !== l2 && "download" !== l2 && "rowSpan" !== l2 && "colSpan" !== l2 && l2 in n2)
          try {
            n2[l2] = null == u2 ? "" : u2;
            break n;
          } catch (n3) {
          }
        "function" == typeof u2 || (null == u2 || false === u2 && "-" !== l2[4] ? n2.removeAttribute(l2) : n2.setAttribute(l2, u2));
      }
  }
  function j(n2) {
    return this.l[n2.type + false](l.event ? l.event(n2) : n2);
  }
  function z(n2) {
    return this.l[n2.type + true](l.event ? l.event(n2) : n2);
  }
  function L(n2, u2, t2, i2, o3, r2, f2, e2, c2, s2) {
    var a2, p2, y2, d2, _2, g2, m2, w2, x2, C2, S, $2, A2, H2, I2, T2 = u2.type;
    if (void 0 !== u2.constructor)
      return null;
    null != t2.__h && (c2 = t2.__h, e2 = u2.__e = t2.__e, u2.__h = null, r2 = [e2]), (a2 = l.__b) && a2(u2);
    try {
      n:
        if ("function" == typeof T2) {
          if (w2 = u2.props, x2 = (a2 = T2.contextType) && i2[a2.__c], C2 = a2 ? x2 ? x2.props.value : a2.__ : i2, t2.__c ? m2 = (p2 = u2.__c = t2.__c).__ = p2.__E : ("prototype" in T2 && T2.prototype.render ? u2.__c = p2 = new T2(w2, C2) : (u2.__c = p2 = new b(w2, C2), p2.constructor = T2, p2.render = B), x2 && x2.sub(p2), p2.props = w2, p2.state || (p2.state = {}), p2.context = C2, p2.__n = i2, y2 = p2.__d = true, p2.__h = [], p2._sb = []), null == p2.__s && (p2.__s = p2.state), null != T2.getDerivedStateFromProps && (p2.__s == p2.state && (p2.__s = v({}, p2.__s)), v(p2.__s, T2.getDerivedStateFromProps(w2, p2.__s))), d2 = p2.props, _2 = p2.state, p2.__v = u2, y2)
            null == T2.getDerivedStateFromProps && null != p2.componentWillMount && p2.componentWillMount(), null != p2.componentDidMount && p2.__h.push(p2.componentDidMount);
          else {
            if (null == T2.getDerivedStateFromProps && w2 !== d2 && null != p2.componentWillReceiveProps && p2.componentWillReceiveProps(w2, C2), !p2.__e && (null != p2.shouldComponentUpdate && false === p2.shouldComponentUpdate(w2, p2.__s, C2) || u2.__v === t2.__v)) {
              for (u2.__v !== t2.__v && (p2.props = w2, p2.state = p2.__s, p2.__d = false), u2.__e = t2.__e, u2.__k = t2.__k, u2.__k.forEach(function(n3) {
                n3 && (n3.__ = u2);
              }), S = 0; S < p2._sb.length; S++)
                p2.__h.push(p2._sb[S]);
              p2._sb = [], p2.__h.length && f2.push(p2);
              break n;
            }
            null != p2.componentWillUpdate && p2.componentWillUpdate(w2, p2.__s, C2), null != p2.componentDidUpdate && p2.__h.push(function() {
              p2.componentDidUpdate(d2, _2, g2);
            });
          }
          if (p2.context = C2, p2.props = w2, p2.__P = n2, p2.__e = false, $2 = l.__r, A2 = 0, "prototype" in T2 && T2.prototype.render) {
            for (p2.state = p2.__s, p2.__d = false, $2 && $2(u2), a2 = p2.render(p2.props, p2.state, p2.context), H2 = 0; H2 < p2._sb.length; H2++)
              p2.__h.push(p2._sb[H2]);
            p2._sb = [];
          } else
            do {
              p2.__d = false, $2 && $2(u2), a2 = p2.render(p2.props, p2.state, p2.context), p2.state = p2.__s;
            } while (p2.__d && ++A2 < 25);
          p2.state = p2.__s, null != p2.getChildContext && (i2 = v(v({}, i2), p2.getChildContext())), y2 || null == p2.getSnapshotBeforeUpdate || (g2 = p2.getSnapshotBeforeUpdate(d2, _2)), P(n2, h(I2 = null != a2 && a2.type === k && null == a2.key ? a2.props.children : a2) ? I2 : [I2], u2, t2, i2, o3, r2, f2, e2, c2, s2), p2.base = u2.__e, u2.__h = null, p2.__h.length && f2.push(p2), m2 && (p2.__E = p2.__ = null);
        } else
          null == r2 && u2.__v === t2.__v ? (u2.__k = t2.__k, u2.__e = t2.__e) : u2.__e = N(t2.__e, u2, t2, i2, o3, r2, f2, c2, s2);
      (a2 = l.diffed) && a2(u2);
    } catch (n3) {
      u2.__v = null, (c2 || null != r2) && (u2.__e = e2, u2.__h = !!c2, r2[r2.indexOf(e2)] = null), l.__e(n3, u2, t2);
    }
  }
  function M(n2, u2, t2) {
    for (var i2 = 0; i2 < t2.length; i2++)
      O(t2[i2], t2[++i2], t2[++i2]);
    l.__c && l.__c(u2, n2), n2.some(function(u3) {
      try {
        n2 = u3.__h, u3.__h = [], n2.some(function(n3) {
          n3.call(u3);
        });
      } catch (n3) {
        l.__e(n3, u3.__v);
      }
    });
  }
  function N(l2, u2, t2, i2, o3, r2, f2, e2, s2) {
    var a2, v2, y2, d2 = t2.props, _2 = u2.props, k2 = u2.type, b2 = 0;
    if ("svg" === k2 && (o3 = true), null != r2) {
      for (; b2 < r2.length; b2++)
        if ((a2 = r2[b2]) && "setAttribute" in a2 == !!k2 && (k2 ? a2.localName === k2 : 3 === a2.nodeType)) {
          l2 = a2, r2[b2] = null;
          break;
        }
    }
    if (null == l2) {
      if (null === k2)
        return document.createTextNode(_2);
      l2 = o3 ? document.createElementNS("http://www.w3.org/2000/svg", k2) : document.createElement(k2, _2.is && _2), r2 = null, e2 = false;
    }
    if (null === k2)
      d2 === _2 || e2 && l2.data === _2 || (l2.data = _2);
    else {
      if (r2 = r2 && n.call(l2.childNodes), v2 = (d2 = t2.props || c).dangerouslySetInnerHTML, y2 = _2.dangerouslySetInnerHTML, !e2) {
        if (null != r2)
          for (d2 = {}, b2 = 0; b2 < l2.attributes.length; b2++)
            d2[l2.attributes[b2].name] = l2.attributes[b2].value;
        (y2 || v2) && (y2 && (v2 && y2.__html == v2.__html || y2.__html === l2.innerHTML) || (l2.innerHTML = y2 && y2.__html || ""));
      }
      if (H(l2, _2, d2, o3, e2), y2)
        u2.__k = [];
      else if (P(l2, h(b2 = u2.props.children) ? b2 : [b2], u2, t2, i2, o3 && "foreignObject" !== k2, r2, f2, r2 ? r2[0] : t2.__k && g(t2, 0), e2, s2), null != r2)
        for (b2 = r2.length; b2--; )
          null != r2[b2] && p(r2[b2]);
      e2 || ("value" in _2 && void 0 !== (b2 = _2.value) && (b2 !== l2.value || "progress" === k2 && !b2 || "option" === k2 && b2 !== d2.value) && T(l2, "value", b2, d2.value, false), "checked" in _2 && void 0 !== (b2 = _2.checked) && b2 !== l2.checked && T(l2, "checked", b2, d2.checked, false));
    }
    return l2;
  }
  function O(n2, u2, t2) {
    try {
      "function" == typeof n2 ? n2(u2) : n2.current = u2;
    } catch (n3) {
      l.__e(n3, t2);
    }
  }
  function q(n2, u2, t2) {
    var i2, o3;
    if (l.unmount && l.unmount(n2), (i2 = n2.ref) && (i2.current && i2.current !== n2.__e || O(i2, null, u2)), null != (i2 = n2.__c)) {
      if (i2.componentWillUnmount)
        try {
          i2.componentWillUnmount();
        } catch (n3) {
          l.__e(n3, u2);
        }
      i2.base = i2.__P = null, n2.__c = void 0;
    }
    if (i2 = n2.__k)
      for (o3 = 0; o3 < i2.length; o3++)
        i2[o3] && q(i2[o3], u2, t2 || "function" != typeof n2.type);
    t2 || null == n2.__e || p(n2.__e), n2.__ = n2.__e = n2.__d = void 0;
  }
  function B(n2, l2, u2) {
    return this.constructor(n2, u2);
  }
  function D(u2, t2, i2) {
    var o3, r2, f2, e2;
    l.__ && l.__(u2, t2), r2 = (o3 = "function" == typeof i2) ? null : i2 && i2.__k || t2.__k, f2 = [], e2 = [], L(t2, u2 = (!o3 && i2 || t2).__k = y(k, null, [u2]), r2 || c, c, void 0 !== t2.ownerSVGElement, !o3 && i2 ? [i2] : r2 ? null : t2.firstChild ? n.call(t2.childNodes) : null, f2, !o3 && i2 ? i2 : r2 ? r2.__e : t2.firstChild, o3, e2), M(f2, u2, e2);
  }
  n = s.slice, l = { __e: function(n2, l2, u2, t2) {
    for (var i2, o3, r2; l2 = l2.__; )
      if ((i2 = l2.__c) && !i2.__)
        try {
          if ((o3 = i2.constructor) && null != o3.getDerivedStateFromError && (i2.setState(o3.getDerivedStateFromError(n2)), r2 = i2.__d), null != i2.componentDidCatch && (i2.componentDidCatch(n2, t2 || {}), r2 = i2.__d), r2)
            return i2.__E = i2;
        } catch (l3) {
          n2 = l3;
        }
    throw n2;
  } }, u = 0, t = function(n2) {
    return null != n2 && void 0 === n2.constructor;
  }, b.prototype.setState = function(n2, l2) {
    var u2;
    u2 = null != this.__s && this.__s !== this.state ? this.__s : this.__s = v({}, this.state), "function" == typeof n2 && (n2 = n2(v({}, u2), this.props)), n2 && v(u2, n2), null != n2 && this.__v && (l2 && this._sb.push(l2), w(this));
  }, b.prototype.forceUpdate = function(n2) {
    this.__v && (this.__e = true, n2 && this.__h.push(n2), w(this));
  }, b.prototype.render = k, i = [], r = "function" == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout, f = function(n2, l2) {
    return n2.__v.__b - l2.__v.__b;
  }, x.__r = 0, e = 0;

  // src/escape.tsx
  var htmlEscapes = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;"
  };
  function escapeHtmlChar(key) {
    return Object.prototype.hasOwnProperty.call(htmlEscapes, key) ? htmlEscapes[key] : void 0;
  }
  var reUnescapedHtml = /[&<>"']/g;
  var reHasUnescapedHtml = RegExp(reUnescapedHtml.source);
  function escape(string) {
    string = String(string);
    return string && reHasUnescapedHtml.test(string) ? string.replace(reUnescapedHtml, escapeHtmlChar) : string;
  }

  // node_modules/.pnpm/accessible-autocomplete@2.0.4_patch_hash=tffdj7al3ah46sd5qkqm6dizqi/node_modules/accessible-autocomplete/src/status.js
  var debounce = function(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this;
      var args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate)
          func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow)
        func.apply(context, args);
    };
  };
  var statusDebounceMillis = 1400;
  var Status = class extends b {
    constructor() {
      super(...arguments);
      __publicField(this, "state", {
        bump: false,
        debounced: false
      });
    }
    componentWillMount() {
      const that = this;
      this.debounceStatusUpdate = debounce(function() {
        if (!that.state.debounced) {
          const shouldSilence = !that.props.isInFocus || that.props.validChoiceMade;
          that.setState(({ bump }) => ({ bump: !bump, debounced: true, silenced: shouldSilence }));
        }
      }, statusDebounceMillis);
    }
    componentWillReceiveProps({ queryLength }) {
      this.setState({ debounced: false });
    }
    render() {
      const {
        id,
        length,
        queryLength,
        minQueryLength,
        selectedOption,
        selectedOptionIndex,
        tQueryTooShort,
        tNoResults,
        tSelectedOption,
        tResults
      } = this.props;
      const { bump, debounced, silenced } = this.state;
      const queryTooShort = queryLength < minQueryLength;
      const noResults = length === 0;
      const contentSelectedOption = selectedOption ? tSelectedOption(selectedOption, length, selectedOptionIndex) : "";
      let content = null;
      if (queryTooShort) {
        content = tQueryTooShort(minQueryLength);
      } else if (noResults) {
        content = tNoResults();
      } else {
        content = tResults(length, contentSelectedOption);
      }
      this.debounceStatusUpdate();
      return /* @__PURE__ */ y(
        "div",
        {
          style: {
            border: "0",
            clip: "rect(0 0 0 0)",
            height: "1px",
            marginBottom: "-1px",
            marginRight: "-1px",
            overflow: "hidden",
            padding: "0",
            position: "absolute",
            whiteSpace: "nowrap",
            width: "1px"
          }
        },
        /* @__PURE__ */ y(
          "div",
          {
            id: id + "__status--A",
            role: "status",
            "aria-atomic": "true",
            "aria-live": "polite"
          },
          !silenced && debounced && bump ? content : ""
        ),
        /* @__PURE__ */ y(
          "div",
          {
            id: id + "__status--B",
            role: "status",
            "aria-atomic": "true",
            "aria-live": "polite"
          },
          !silenced && debounced && !bump ? content : ""
        )
      );
    }
  };
  __publicField(Status, "defaultProps", {
    tQueryTooShort: (minQueryLength) => `Type in ${minQueryLength} or more characters for results`,
    tNoResults: () => "No search results",
    tSelectedOption: (selectedOption, length, index) => `${selectedOption} ${index + 1} of ${length} is highlighted`,
    tResults: (length, contentSelectedOption) => {
      const words = {
        result: length === 1 ? "result" : "results",
        is: length === 1 ? "is" : "are"
      };
      return `${length} ${words.result} ${words.is} available. ${contentSelectedOption}`;
    }
  });

  // node_modules/.pnpm/accessible-autocomplete@2.0.4_patch_hash=tffdj7al3ah46sd5qkqm6dizqi/node_modules/accessible-autocomplete/src/dropdown-arrow-down.js
  var DropdownArrowDown = ({ className }) => /* @__PURE__ */ y("svg", { version: "1.1", xmlns: "http://www.w3.org/2000/svg", className, focusable: "false" }, /* @__PURE__ */ y("g", { stroke: "none", fill: "none", "fill-rule": "evenodd" }, /* @__PURE__ */ y("polygon", { fill: "#000000", points: "0 0 22 0 11 17" })));
  var dropdown_arrow_down_default = DropdownArrowDown;

  // node_modules/.pnpm/accessible-autocomplete@2.0.4_patch_hash=tffdj7al3ah46sd5qkqm6dizqi/node_modules/accessible-autocomplete/src/autocomplete.js
  var IS_PREACT = false;
  var IS_REACT = false;
  var keyCodes = {
    13: "enter",
    27: "escape",
    32: "space",
    38: "up",
    40: "down"
  };
  function isIosDevice() {
    return typeof navigator !== "undefined" && !!(navigator.userAgent.match(/(iPod|iPhone|iPad)/g) && navigator.userAgent.match(/AppleWebKit/g));
  }
  function isPrintableKeyCode(keyCode) {
    return keyCode > 47 && keyCode < 58 || // number keys
    keyCode === 32 || keyCode === 8 || // spacebar or backspace
    keyCode > 64 && keyCode < 91 || // letter keys
    keyCode > 95 && keyCode < 112 || // numpad keys
    keyCode > 185 && keyCode < 193 || // ;=,-./` (in order)
    keyCode > 218 && keyCode < 223;
  }
  function onChangeCrossLibrary(handler) {
    if (IS_PREACT) {
      return { onInput: handler };
    }
    if (IS_REACT) {
      return { onChange: handler };
    }
  }
  var Autocomplete = class extends b {
    constructor(props) {
      super(props);
      __publicField(this, "elementReferences", {});
      this.state = {
        focused: null,
        hovered: null,
        menuOpen: false,
        options: props.defaultValue ? [props.defaultValue] : [],
        query: props.defaultValue ? this.templateInputValue(props.defaultValue) : "",
        validChoiceMade: false,
        selected: null,
        ariaHint: true
      };
      this.handleComponentBlur = this.handleComponentBlur.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
      this.handleUpArrow = this.handleUpArrow.bind(this);
      this.handleDownArrow = this.handleDownArrow.bind(this);
      this.handleEnter = this.handleEnter.bind(this);
      this.handlePrintableKey = this.handlePrintableKey.bind(this);
      this.handleListMouseLeave = this.handleListMouseLeave.bind(this);
      this.handleOptionBlur = this.handleOptionBlur.bind(this);
      this.handleOptionClick = this.handleOptionClick.bind(this);
      this.handleOptionFocus = this.handleOptionFocus.bind(this);
      this.handleOptionMouseDown = this.handleOptionMouseDown.bind(this);
      this.handleOptionMouseEnter = this.handleOptionMouseEnter.bind(this);
      this.handleInputBlur = this.handleInputBlur.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleInputFocus = this.handleInputFocus.bind(this);
      this.pollInputElement = this.pollInputElement.bind(this);
      this.getDirectInputChanges = this.getDirectInputChanges.bind(this);
    }
    isQueryAnOption(query, options) {
      return options.map((entry) => this.templateInputValue(entry).toLowerCase()).indexOf(query.toLowerCase()) !== -1;
    }
    componentDidMount() {
      this.pollInputElement();
    }
    componentWillUnmount() {
      clearTimeout(this.$pollInput);
    }
    // Applications like Dragon NaturallySpeaking will modify the
    // `input` field by directly changing its `.value`. These events
    // don't trigger our JavaScript event listeners, so we need to poll
    // to handle when and if they occur.
    pollInputElement() {
      this.getDirectInputChanges();
      this.$pollInput = setTimeout(() => {
        this.pollInputElement();
      }, 100);
    }
    getDirectInputChanges() {
      const inputReference = this.elementReferences[-1];
      const queryHasChanged = inputReference && inputReference.value !== this.state.query;
      if (queryHasChanged) {
        this.handleInputChange({ target: { value: inputReference.value } });
      }
    }
    componentDidUpdate(prevProps, prevState) {
      const { focused } = this.state;
      const componentLostFocus = focused === null;
      const focusedChanged = prevState.focused !== focused;
      const focusDifferentElement = focusedChanged && !componentLostFocus;
      if (focusDifferentElement) {
        this.elementReferences[focused].focus();
      }
      const focusedInput = focused === -1;
      const componentGainedFocus = focusedChanged && prevState.focused === null;
      const selectAllText = focusedInput && componentGainedFocus;
      if (selectAllText) {
        const inputElement = this.elementReferences[focused];
        inputElement.setSelectionRange(0, inputElement.value.length);
      }
    }
    hasAutoselect() {
      return isIosDevice() ? false : this.props.autoselect;
    }
    // This template is used when converting from a state.options object into a state.query.
    templateInputValue(value) {
      const inputValueTemplate = this.props.templates && this.props.templates.inputValue;
      return inputValueTemplate ? inputValueTemplate(value) : value;
    }
    // This template is used when displaying results / suggestions.
    templateSuggestion(value) {
      const suggestionTemplate = this.props.templates && this.props.templates.suggestion;
      return suggestionTemplate ? suggestionTemplate(value) : value;
    }
    handleComponentBlur(newState) {
      const { options, query, selected } = this.state;
      let newQuery;
      if (this.props.confirmOnBlur) {
        newQuery = newState.query || query;
        this.props.onConfirm(options[selected]);
      } else {
        newQuery = query;
      }
      this.setState({
        focused: null,
        menuOpen: newState.menuOpen || false,
        query: newQuery,
        selected: null,
        validChoiceMade: this.isQueryAnOption(newQuery, options)
      });
    }
    handleListMouseLeave(event) {
      this.setState({
        hovered: null
      });
    }
    handleOptionBlur(event, index) {
      const { focused, menuOpen, options, selected } = this.state;
      const focusingOutsideComponent = event.relatedTarget === null;
      const focusingInput = event.relatedTarget === this.elementReferences[-1];
      const focusingAnotherOption = focused !== index && focused !== -1;
      const blurComponent = !focusingAnotherOption && focusingOutsideComponent || !(focusingAnotherOption || focusingInput);
      if (blurComponent) {
        const keepMenuOpen = menuOpen && isIosDevice();
        this.handleComponentBlur({
          menuOpen: keepMenuOpen,
          query: this.templateInputValue(options[selected])
        });
      }
    }
    handleInputBlur(event) {
      const { focused, menuOpen, options, query, selected } = this.state;
      const focusingAnOption = focused !== -1;
      if (!focusingAnOption) {
        const keepMenuOpen = menuOpen && isIosDevice();
        const newQuery = isIosDevice() ? query : this.templateInputValue(options[selected]);
        this.handleComponentBlur({
          menuOpen: keepMenuOpen,
          query: newQuery
        });
      }
    }
    handleInputChange(event) {
      const { minLength, source, showAllValues } = this.props;
      const autoselect = this.hasAutoselect();
      const query = event.target.value;
      const queryEmpty = query.length === 0;
      const queryChanged = this.state.query.length !== query.length;
      const queryLongEnough = query.length >= minLength;
      this.setState({
        query,
        ariaHint: queryEmpty
      });
      const searchForOptions = showAllValues || !queryEmpty && queryChanged && queryLongEnough;
      if (searchForOptions) {
        source(query, (options) => {
          const optionsAvailable = options.length > 0;
          this.setState({
            menuOpen: optionsAvailable,
            options,
            selected: autoselect && optionsAvailable ? 0 : -1,
            validChoiceMade: false
          });
        });
      } else if (queryEmpty || !queryLongEnough) {
        this.setState({
          menuOpen: false,
          options: []
        });
      }
    }
    handleInputClick(event) {
      this.handleInputChange(event);
    }
    handleInputFocus(event) {
      const { query, validChoiceMade, options } = this.state;
      const { minLength } = this.props;
      const shouldReopenMenu = !validChoiceMade && query.length >= minLength && options.length > 0;
      if (shouldReopenMenu) {
        this.setState(({ menuOpen }) => ({ focused: -1, menuOpen: shouldReopenMenu || menuOpen, selected: -1 }));
      } else {
        this.setState({ focused: -1 });
      }
    }
    handleOptionFocus(index) {
      this.setState({
        focused: index,
        hovered: null,
        selected: index
      });
    }
    handleOptionMouseEnter(event, index) {
      if (!isIosDevice()) {
        this.setState({
          hovered: index
        });
      }
    }
    handleOptionClick(event, index) {
      const selectedOption = this.state.options[index];
      const newQuery = this.templateInputValue(selectedOption);
      this.props.onConfirm(selectedOption);
      this.setState({
        focused: -1,
        hovered: null,
        menuOpen: false,
        query: newQuery,
        selected: -1,
        validChoiceMade: true
      });
      this.forceUpdate();
    }
    handleOptionMouseDown(event) {
      event.preventDefault();
    }
    handleUpArrow(event) {
      event.preventDefault();
      const { menuOpen, selected } = this.state;
      const isNotAtTop = selected !== -1;
      const allowMoveUp = isNotAtTop && menuOpen;
      if (allowMoveUp) {
        this.handleOptionFocus(selected - 1);
      }
    }
    handleDownArrow(event) {
      event.preventDefault();
      if (this.props.showAllValues && this.state.menuOpen === false) {
        event.preventDefault();
        this.props.source("", (options) => {
          this.setState({
            menuOpen: true,
            options,
            selected: 0,
            focused: 0,
            hovered: null
          });
        });
      } else if (this.state.menuOpen === true) {
        const { menuOpen, options, selected } = this.state;
        const isNotAtBottom = selected !== options.length - 1;
        const allowMoveDown = isNotAtBottom && menuOpen;
        if (allowMoveDown) {
          this.handleOptionFocus(selected + 1);
        }
      }
    }
    handleSpace(event) {
      if (this.props.showAllValues && this.state.menuOpen === false && this.state.query === "") {
        event.preventDefault();
        this.props.source("", (options) => {
          this.setState({
            menuOpen: true,
            options
          });
        });
      }
      const focusIsOnOption = this.state.focused !== -1;
      if (focusIsOnOption) {
        event.preventDefault();
        this.handleOptionClick(event, this.state.focused);
      }
    }
    handleEnter(event) {
      if (this.state.menuOpen) {
        event.preventDefault();
        const hasSelectedOption = this.state.selected >= 0;
        if (hasSelectedOption) {
          this.handleOptionClick(event, this.state.selected);
        }
      }
    }
    handlePrintableKey(event) {
      const inputElement = this.elementReferences[-1];
      const eventIsOnInput = event.target === inputElement;
      if (!eventIsOnInput) {
        inputElement.focus();
      }
    }
    handleKeyDown(event) {
      switch (keyCodes[event.keyCode]) {
        case "up":
          this.handleUpArrow(event);
          break;
        case "down":
          this.handleDownArrow(event);
          break;
        case "space":
          this.handleSpace(event);
          break;
        case "enter":
          this.handleEnter(event);
          break;
        case "escape":
          this.handleComponentBlur({
            query: this.state.query
          });
          break;
        default:
          if (isPrintableKeyCode(event.keyCode)) {
            this.handlePrintableKey(event);
          }
          break;
      }
    }
    render() {
      const {
        cssNamespace,
        displayMenu,
        id,
        minLength,
        name,
        placeholder,
        required,
        showAllValues,
        tNoResults,
        tStatusQueryTooShort,
        tStatusNoResults,
        tStatusSelectedOption,
        tStatusResults,
        tAssistiveHint,
        dropdownArrow: dropdownArrowFactory
      } = this.props;
      const { focused, hovered, menuOpen, options, query, selected, ariaHint, validChoiceMade } = this.state;
      const autoselect = this.hasAutoselect();
      const inputFocused = focused === -1;
      const noOptionsAvailable = options.length === 0;
      const queryNotEmpty = query.length !== 0;
      const queryLongEnough = query.length >= minLength;
      const showNoOptionsFound = this.props.showNoOptionsFound && inputFocused && noOptionsAvailable && queryNotEmpty && queryLongEnough;
      const wrapperClassName = `${cssNamespace}__wrapper`;
      const inputClassName = `${cssNamespace}__input`;
      const componentIsFocused = focused !== null;
      const inputModifierFocused = componentIsFocused ? ` ${inputClassName}--focused` : "";
      const inputModifierType = this.props.showAllValues ? ` ${inputClassName}--show-all-values` : ` ${inputClassName}--default`;
      const dropdownArrowClassName = `${cssNamespace}__dropdown-arrow-down`;
      const optionFocused = focused !== -1 && focused !== null;
      const menuClassName = `${cssNamespace}__menu`;
      const menuModifierDisplayMenu = `${menuClassName}--${displayMenu}`;
      const menuIsVisible = menuOpen || showNoOptionsFound;
      const menuModifierVisibility = `${menuClassName}--${menuIsVisible ? "visible" : "hidden"}`;
      const optionClassName = `${cssNamespace}__option`;
      const hintClassName = `${cssNamespace}__hint`;
      const selectedOptionText = this.templateInputValue(options[selected]);
      const optionBeginsWithQuery = selectedOptionText && selectedOptionText.toLowerCase().indexOf(query.toLowerCase()) === 0;
      const hintValue = optionBeginsWithQuery && autoselect ? query + selectedOptionText.substr(query.length) : "";
      const assistiveHintID = id + "__assistiveHint";
      const ariaDescribedProp = ariaHint ? {
        "aria-describedby": assistiveHintID
      } : null;
      let dropdownArrow;
      if (showAllValues) {
        dropdownArrow = dropdownArrowFactory({ className: dropdownArrowClassName });
        if (typeof dropdownArrow === "string") {
          dropdownArrow = /* @__PURE__ */ y("div", { className: `${cssNamespace}__dropdown-arrow-down-wrapper`, dangerouslySetInnerHTML: { __html: dropdownArrow } });
        }
      }
      return /* @__PURE__ */ y("div", { className: wrapperClassName, onKeyDown: this.handleKeyDown }, /* @__PURE__ */ y(
        Status,
        {
          id,
          length: options.length,
          queryLength: query.length,
          minQueryLength: minLength,
          selectedOption: this.templateInputValue(options[selected]),
          selectedOptionIndex: selected,
          validChoiceMade,
          isInFocus: this.state.focused !== null,
          tQueryTooShort: tStatusQueryTooShort,
          tNoResults: tStatusNoResults,
          tSelectedOption: tStatusSelectedOption,
          tResults: tStatusResults
        }
      ), hintValue && /* @__PURE__ */ y("span", null, /* @__PURE__ */ y("input", { className: hintClassName, readonly: true, tabIndex: "-1", value: hintValue })), /* @__PURE__ */ y(
        "input",
        __spreadProps(__spreadValues(__spreadProps(__spreadValues({
          "aria-expanded": menuOpen ? "true" : "false",
          "aria-activedescendant": optionFocused ? `${id}__option--${focused}` : "",
          "aria-owns": `${id}__listbox`,
          "aria-autocomplete": this.hasAutoselect() ? "both" : "list"
        }, ariaDescribedProp), {
          autoComplete: "off",
          className: `${inputClassName}${inputModifierFocused}${inputModifierType}`,
          id,
          onClick: (event) => this.handleInputClick(event),
          onBlur: this.handleInputBlur
        }), onChangeCrossLibrary(this.handleInputChange)), {
          onFocus: this.handleInputFocus,
          name,
          placeholder,
          ref: (inputElement) => {
            this.elementReferences[-1] = inputElement;
          },
          type: "text",
          role: "combobox",
          required,
          value: query
        })
      ), dropdownArrow, /* @__PURE__ */ y(
        "ul",
        {
          className: `${menuClassName} ${menuModifierDisplayMenu} ${menuModifierVisibility}`,
          onMouseLeave: (event) => this.handleListMouseLeave(event),
          id: `${id}__listbox`,
          role: "listbox"
        },
        options.map((option, index) => {
          const showFocused = focused === -1 ? selected === index : focused === index;
          const optionModifierFocused = showFocused && hovered === null ? ` ${optionClassName}--focused` : "";
          const optionModifierOdd = index % 2 ? ` ${optionClassName}--odd` : "";
          const iosPosinsetHtml = isIosDevice() ? `<span id=${id}__option-suffix--${index} style="border:0;clip:rect(0 0 0 0);height:1px;marginBottom:-1px;marginRight:-1px;overflow:hidden;padding:0;position:absolute;whiteSpace:nowrap;width:1px"> ${index + 1} of ${options.length}</span>` : "";
          return /* @__PURE__ */ y(
            "li",
            {
              "aria-selected": focused === index ? "true" : "false",
              className: `${optionClassName}${optionModifierFocused}${optionModifierOdd}`,
              dangerouslySetInnerHTML: { __html: this.templateSuggestion(option) + iosPosinsetHtml },
              id: `${id}__option--${index}`,
              key: index,
              onBlur: (event) => this.handleOptionBlur(event, index),
              onClick: (event) => this.handleOptionClick(event, index),
              onMouseDown: this.handleOptionMouseDown,
              onMouseEnter: (event) => this.handleOptionMouseEnter(event, index),
              ref: (optionEl) => {
                this.elementReferences[index] = optionEl;
              },
              role: "option",
              tabIndex: "-1",
              "aria-posinset": index + 1,
              "aria-setsize": options.length
            }
          );
        }),
        showNoOptionsFound && /* @__PURE__ */ y("li", { className: `${optionClassName} ${optionClassName}--no-results` }, tNoResults())
      ), /* @__PURE__ */ y("span", { id: assistiveHintID, style: { display: "none" } }, tAssistiveHint()));
    }
  };
  __publicField(Autocomplete, "defaultProps", {
    autoselect: false,
    cssNamespace: "autocomplete",
    defaultValue: "",
    displayMenu: "inline",
    minLength: 0,
    name: "input-autocomplete",
    placeholder: "",
    onConfirm: () => {
    },
    confirmOnBlur: true,
    showNoOptionsFound: true,
    showAllValues: false,
    required: false,
    tNoResults: () => "No results found",
    tAssistiveHint: () => "When autocomplete results are available use up and down arrows to review and enter to select.  Touch device users, explore by touch or with swipe gestures.",
    dropdownArrow: dropdown_arrow_down_default
  });

  // node_modules/.pnpm/preact@10.16.0/node_modules/preact/jsx-runtime/dist/jsxRuntime.module.js
  var _ = 0;
  function o2(o3, e2, n2, t2, f2, l2) {
    var s2, u2, a2 = {};
    for (u2 in e2)
      "ref" == u2 ? s2 = e2[u2] : a2[u2] = e2[u2];
    var i2 = { type: o3, props: a2, key: n2, ref: s2, __k: null, __: null, __b: 0, __e: null, __d: void 0, __c: null, __h: null, constructor: void 0, __v: --_, __source: f2, __self: l2 };
    if ("function" == typeof o3 && (s2 = o3.defaultProps))
      for (u2 in s2)
        void 0 === a2[u2] && (a2[u2] = s2[u2]);
    return l.vnode && l.vnode(i2), i2;
  }

  // src/index.tsx
  function createAutocomplete(element, {
    //
    labelId,
    defaultValue,
    search,
    onConfirm,
    onInputValueChange,
    resultsLoadingText = "Loading\u2026",
    noResultsText = "No results found",
    assistiveHintText,
    displayMenu
  }) {
    var lastInputValue;
    var isQuerying = false;
    function handleInput(e2) {
      if (isHTMLInputElement(e2.target) && e2.target.matches("[role='combobox']")) {
        lastInputValue = e2.target.value;
        onInputValueChange(e2.target.value);
      }
    }
    D(
      /* @__PURE__ */ o2(
        Autocomplete,
        {
          id: labelId,
          defaultValue,
          onConfirm: (selectedOption) => {
            if (selectedOption != null) {
              onInputValueChange(selectedOption.displayName);
            }
            onConfirm == null ? void 0 : onConfirm(selectedOption);
          },
          source: (query, syncResults) => {
            lastInputValue = query;
            isQuerying = true;
            syncResults([]);
            search(query, (results) => {
              if (lastInputValue === query) {
                isQuerying = false;
                syncResults(results);
              }
            });
          },
          tNoResults: () => isQuerying ? resultsLoadingText : noResultsText,
          tAssistiveHint: assistiveHintText ? () => assistiveHintText : void 0,
          templates: {
            inputValue: (item) => item ? escape(item.displayName) : "",
            suggestion: (item) => item ? escape(item.displayName) : ""
          },
          displayMenu
        }
      ),
      element
    );
    element.addEventListener("input", handleInput, false);
    return () => {
      D(() => null, element);
      element.removeEventListener("input", handleInput, false);
    };
  }
  function isHTMLInputElement(e2) {
    return e2 != null && e2.tagName === "INPUT";
  }
  return __toCommonJS(src_exports);
})();
