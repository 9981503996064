define("ember-template-compiler/lib/plugins/transform-link-to", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display", "ember-template-compiler/lib/plugins/utils"], function (_exports, _debug, _calculateLocationDisplay, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformLinkTo;

  function isInlineLinkTo(node) {
    return (0, _utils.isPath)(node.path) && node.path.original === 'link-to';
  }

  function isBlockLinkTo(node) {
    return (0, _utils.isPath)(node.path) && node.path.original === 'link-to';
  }

  function isQueryParams(node) {
    return (0, _utils.isSubExpression)(node) && (0, _utils.isPath)(node.path) && node.path.original === 'query-params';
  }

  function transformInlineLinkToIntoBlockForm(env, node) {
    var b = env.syntax.builders;
    return b.block('link-to', node.params.slice(1), node.hash, b.blockItself([buildStatement(b, node.params[0], node.escaped, node.loc)], undefined, false, node.loc), null, node.loc);
  }

  function transformPositionalLinkToIntoNamedArguments(env, node, hasBlock) {
    if (hasBlock === void 0) {
      hasBlock = true;
    }

    var _a, _b;

    var b = env.syntax.builders;
    var moduleName = (_a = env.meta) === null || _a === void 0 ? void 0 : _a.moduleName;
    var params = node.params,
        pairs = node.hash.pairs;
    var keys = pairs.map(function (pair) {
      return pair.key;
    });

    if (params.length === 0) {
      (true && !(keys.indexOf('params') !== -1 || keys.indexOf('route') !== -1 || keys.indexOf('model') !== -1 || keys.indexOf('models') !== -1 || keys.indexOf('query') !== -1) && (0, _debug.assert)("You must provide one or more parameters to the `{{link-to}}` component. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), keys.indexOf('params') !== -1 || keys.indexOf('route') !== -1 || keys.indexOf('model') !== -1 || keys.indexOf('models') !== -1 || keys.indexOf('query') !== -1));
      return node;
    } else {
      (true && !(keys.indexOf('params') === -1) && (0, _debug.assert)("You cannot pass positional parameters and the `params` argument to the `{{link-to}}` component at the same time. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), keys.indexOf('params') === -1));
      (true && !(keys.indexOf('route') === -1) && (0, _debug.assert)("You cannot pass positional parameters and the `route` argument to the `{{link-to}}` component at the same time. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), keys.indexOf('route') === -1));
      (true && !(keys.indexOf('model') === -1) && (0, _debug.assert)("You cannot pass positional parameters and the `model` argument to the `{{link-to}}` component at the same time. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), keys.indexOf('model') === -1));
      (true && !(keys.indexOf('models') === -1) && (0, _debug.assert)("You cannot pass positional parameters and the `models` argument to the `{{link-to}}` component at the same time. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), keys.indexOf('models') === -1));
      (true && !(keys.indexOf('query') === -1) && (0, _debug.assert)("You cannot pass positional parameters and the `query` argument to the `{{link-to}}` component at the same time. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), keys.indexOf('query') === -1));
    }

    (true && !(params.length > 0) && (0, _debug.assert)("You must provide one or more parameters to the `{{link-to}}` component. " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), params.length > 0));
    var equivalentNamedArgs = [];
    var hasQueryParams = false; // 1. The last argument is possibly the `query` object.

    var query = params[params.length - 1];

    if (query && isQueryParams(query)) {
      params.pop();
      (true && !(query.params.length === 0) && (0, _debug.assert)("The `(query-params ...)` helper does not take positional arguments. " + (0, _calculateLocationDisplay.default)(moduleName, query.loc), query.params.length === 0));
      pairs.push(b.pair('query', b.sexpr(b.path('-hash', query.path.loc), [], query.hash, query.loc), query.loc));
      hasQueryParams = true;
    } // 2. If there is a `route`, it is now at index 0.


    var route = params.shift();

    if (route) {
      pairs.push(b.pair('route', route, route.loc));
      equivalentNamedArgs.push('`@route`');
    } // 3. Any remaining indices (if any) are `models`.


    if (params.length === 1) {
      pairs.push(b.pair('model', params[0], params[0].loc));
      equivalentNamedArgs.push('`@model`');
    } else if (params.length > 1) {
      pairs.push(b.pair('models', b.sexpr(b.path('array', node.loc), params, undefined, node.loc), node.loc));
      equivalentNamedArgs.push('`@models`');
    }

    if (hasQueryParams) {
      equivalentNamedArgs.push('`@query`');
    }

    if (equivalentNamedArgs.length > 0) {
      var message = 'Invoking the `<LinkTo>` component with positional arguments is deprecated.';
      message += "Please use the equivalent named arguments (" + equivalentNamedArgs.join(', ') + ")";

      if (hasQueryParams) {
        message += ' along with the `hash` helper';
      }

      if (!hasBlock) {
        message += " and pass a block for the link's content.";
      }

      message += '.';

      if ((_b = node.loc) === null || _b === void 0 ? void 0 : _b.source) {
        message += " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc);
      }

      (true && !(false) && (0, _debug.deprecate)(message, false, {
        id: 'ember-glimmer.link-to.positional-arguments',
        until: '4.0.0',
        for: 'ember-source',
        url: 'https://deprecations.emberjs.com/v3.x#toc_ember-glimmer-link-to-positional-arguments',
        since: {
          enabled: '3.26.0-beta.1'
        }
      }));
    }

    return b.block(node.path, null, b.hash(pairs, node.hash.loc), node.program, node.inverse, node.loc);
  }

  function buildStatement(b, content, escaped, loc) {
    switch (content.type) {
      case 'PathExpression':
        return b.mustache(content, undefined, undefined, !escaped, loc);

      case 'SubExpression':
        return b.mustache(content.path, content.params, content.hash, !escaped, loc);
      // The default case handles literals.

      default:
        return b.text("" + content.value, loc);
    }
  }

  function transformLinkTo(env) {
    return {
      name: 'transform-link-to',
      visitor: {
        MustacheStatement: function MustacheStatement(node) {
          if (isInlineLinkTo(node)) {
            var block = transformInlineLinkToIntoBlockForm(env, node);
            return transformPositionalLinkToIntoNamedArguments(env, block, false);
          }
        },
        BlockStatement: function BlockStatement(node) {
          if (isBlockLinkTo(node)) {
            return transformPositionalLinkToIntoNamedArguments(env, node);
          }
        }
      }
    };
  }
});