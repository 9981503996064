define("ember-template-compiler/lib/plugins/assert-input-helper-without-block", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display", "ember-template-compiler/lib/plugins/utils"], function (_exports, _debug, _calculateLocationDisplay, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = errorOnInputWithContent;

  function errorOnInputWithContent(env) {
    var _a;

    var moduleName = (_a = env.meta) === null || _a === void 0 ? void 0 : _a.moduleName;
    return {
      name: 'assert-input-helper-without-block',
      visitor: {
        BlockStatement: function BlockStatement(node) {
          if ((0, _utils.isPath)(node.path) && node.path.original === 'input') {
            (true && !(false) && (0, _debug.assert)(assertMessage(moduleName, node)));
          }
        }
      }
    };
  }

  function assertMessage(moduleName, node) {
    var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
    return "The {{input}} helper cannot be used in block form. " + sourceInformation;
  }
});