define("ember-template-compiler/lib/plugins/transform-resolutions", ["exports", "@ember/debug", "@glimmer/syntax", "ember-template-compiler/lib/system/calculate-location-display", "ember-template-compiler/lib/plugins/utils"], function (_exports, _debug, _syntax, _calculateLocationDisplay, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformResolutions;

  /**
   @module ember
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
    {{helper "..." ...}}
    ```
  
    with
  
    ```handlebars
    {{helper (-resolve "helper:...") ...}}
    ```
  
    and
  
    ```handlebars
    {{helper ... ...}}
    ```
  
    with
  
    ```handlebars
    {{helper (-disallow-dynamic-resolution ...) ...}}
    ```
  
    and
  
    ```handlebars
    {{modifier "..." ...}}
    ```
  
    with
  
    ```handlebars
    {{modifier (-resolve "modifier:...") ...}}
    ```
    and
  
    ```handlebars
    {{modifier ... ...}}
    ```
  
    with
  
    ```handlebars
    {{modifier (-disallow-dynamic-resolution ...) ...}}
    ```
  
    @private
    @class TransformResolutions
  */
  var TARGETS = Object.freeze(['helper', 'modifier']);

  function transformResolutions(env) {
    var _a;

    var b = env.syntax.builders;
    var moduleName = (_a = env.meta) === null || _a === void 0 ? void 0 : _a.moduleName;

    var _trackLocals = (0, _utils.trackLocals)(),
        hasLocal = _trackLocals.hasLocal,
        tracker = _trackLocals.node;

    var seen;
    return {
      name: 'transform-resolutions',
      visitor: {
        Template: {
          enter: function enter() {
            seen = new Set();
          },
          exit: function exit() {
            seen = undefined;
          }
        },
        Block: tracker,
        ElementNode: {
          keys: {
            children: tracker
          }
        },
        MustacheStatement: function MustacheStatement(node) {
          (true && !(seen) && (0, _debug.assert)('[BUG] seen set should be available', seen));

          if (seen.has(node)) {
            return;
          }

          if ((0, _utils.isPath)(node.path) && !isLocalVariable(node.path, hasLocal) && TARGETS.indexOf(node.path.original) !== -1) {
            var result = b.mustache(node.path, transformParams(b, node.params, node.path.original, moduleName, node.loc), node.hash, node.trusting, node.loc, node.strip); // Avoid double/infinite-processing

            seen.add(result);
            return result;
          }
        },
        SubExpression: function SubExpression(node) {
          (true && !(seen) && (0, _debug.assert)('[BUG] seen set should be available', seen));

          if (seen.has(node)) {
            return;
          }

          if ((0, _utils.isPath)(node.path) && !isLocalVariable(node.path, hasLocal) && TARGETS.indexOf(node.path.original) !== -1) {
            var result = b.sexpr(node.path, transformParams(b, node.params, node.path.original, moduleName, node.loc), node.hash, node.loc); // Avoid double/infinite-processing

            seen.add(result);
            return result;
          }
        }
      }
    };
  }

  function isLocalVariable(node, hasLocal) {
    return !node.this && node.parts.length === 1 && hasLocal(node.parts[0]);
  }

  function transformParams(b, params, type, moduleName, loc) {
    var first = params[0],
        rest = params.slice(1);
    (true && !(first) && (0, _debug.assert)("The " + type + " keyword requires at least one positional arguments " + (0, _calculateLocationDisplay.default)(moduleName, loc), first));

    if ((0, _utils.isStringLiteral)(first)) {
      return [b.sexpr(b.path('-resolve', first.loc), [b.string(type + ":" + first.value)], undefined, first.loc)].concat(rest);
    } else if (true
    /* DEBUG */
    ) {
      return [b.sexpr(b.path('-disallow-dynamic-resolution', first.loc), [first], b.hash([b.pair('type', b.string(type), first.loc), b.pair('loc', b.string((0, _calculateLocationDisplay.default)(moduleName, loc)), first.loc), b.pair('original', b.string((0, _syntax.print)(first)))]), first.loc)].concat(rest);
    } else {
      return params;
    }
  }
});