define("ember-template-compiler/lib/plugins/deprecate-with", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display", "ember-template-compiler/lib/plugins/utils"], function (_exports, _debug, _calculateLocationDisplay, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = deprecateWith;

  /**
   @module ember
  */

  /**
    A Glimmer2 AST transformation that deprecates `{{#with}}` and replace it
    with `{{#let}}` and `{{#if}}` as per RFC 445.
  
    Transforms:
  
    ```handlebars
    {{#with this.foo as |bar|}}
      ...
    {{/with}}
    ```
  
    Into:
  
    ```handlebars
    {{#let this.foo as |bar|}}
      {{#if bar}}
        ...
      {{/if}}
    {{/let}}
    ```
  
    And:
  
    ```handlebars
    {{#with this.foo as |bar|}}
      ...
    {{else}}
      ...
    {{/with}}
    ```
  
    Into:
  
    ```handlebars
    {{#let this.foo as |bar|}}
      {{#if bar}}
        ...
      {{else}}
        ...
      {{/if}}
    {{/let}}
    ```
  
    And issues a deprecation message.
  
    @private
    @class DeprecateWith
  */
  function deprecateWith(env) {
    var _a;

    var moduleName = (_a = env.meta) === null || _a === void 0 ? void 0 : _a.moduleName;
    var b = env.syntax.builders;
    return {
      name: 'deprecate-with',
      visitor: {
        BlockStatement: function BlockStatement(node) {
          if (!(0, _utils.isPath)(node.path) || node.path.original !== 'with') return;
          var params = node.params,
              hash = node.hash,
              program = node.program,
              inverse = node.inverse,
              loc = node.loc,
              openStrip = node.openStrip,
              inverseStrip = node.inverseStrip,
              closeStrip = node.closeStrip;
          var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
          (true && !(params.length === 1) && (0, _debug.assert)("`{{#with}}` takes a single positional argument (the path to alias), received " + displayParams(params) + ". " + sourceInformation, params.length === 1));
          (true && !(hash.pairs.length === 0) && (0, _debug.assert)("`{{#with}}` does not take any named arguments, received " + displayHash(hash) + ". " + sourceInformation, hash.pairs.length === 0));
          (true && !(program.blockParams.length <= 1) && (0, _debug.assert)("`{{#with}}` yields a single block param, received " + displayBlockParams(program.blockParams) + ". " + sourceInformation, program.blockParams.length <= 1));
          var recommendation;

          if (program.blockParams.length === 0) {
            recommendation = 'Use `{{#if}}` instead.';
          } else if (inverse) {
            recommendation = 'Use `{{#let}}` together with `{{#if}} instead.';
          } else {
            recommendation = 'If you always want the block to render, replace `{{#with}}` with `{{#let}}`. ' + 'If you want to conditionally render the block, use `{{#let}}` together with `{{#if}} instead.';
          }

          (true && !(false) && (0, _debug.deprecate)("`{{#with}}` is deprecated. " + recommendation + " " + sourceInformation, false, {
            id: 'ember-glimmer.with-syntax',
            until: '4.0.0',
            for: 'ember-source',
            url: 'https://deprecations.emberjs.com/v3.x/#toc_ember-glimmer-with-syntax',
            since: {
              enabled: '3.26.0-beta.1'
            }
          }));

          if (program.blockParams.length === 0) {
            return b.block('if', params, null, program, inverse, loc, openStrip, inverseStrip, closeStrip);
          } else {
            return b.block('let', params, null, b.blockItself([b.block('if', [b.path(program.blockParams[0])], null, b.blockItself(program.body, [], program.chained, program.loc), inverse, loc, openStrip, inverseStrip, closeStrip)], program.blockParams, false, loc), null, loc, openStrip, inverseStrip, closeStrip);
          }
        }
      }
    };
  }

  function displayParams(params) {
    if (params.length === 0) {
      return 'no positional arguments';
    } else {
      var display = params.map(function (param) {
        return "`" + JSON.stringify(param) + "`";
      }).join(', ');
      return params.length + " positional arguments: " + display;
    }
  }

  function displayHash(_ref) {
    var pairs = _ref.pairs;

    if (pairs.length === 0) {
      return 'no named arguments';
    } else {
      var display = pairs.map(function (pair) {
        return "`" + pair.key + "`";
      }).join(', ');
      return pairs.length + " named arguments: " + display;
    }
  }

  function displayBlockParams(blockParams) {
    if (blockParams.length === 0) {
      return 'no block params';
    } else {
      var display = blockParams.map(function (param) {
        return "`" + param + "`";
      }).join(', ');
      return blockParams.length + " block params: " + display;
    }
  }
});